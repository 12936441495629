import React, {
  useEffect
  , useState,
} from 'react'
import { Navigate, useParams } from 'react-router-dom'

import axios from "axios"
import qs from 'qs'
import DatePicker from "react-datepicker"
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

import "react-datepicker/dist/react-datepicker.css"

import InputNumeric from '../components/InputNumeric'
import NotFound from '../components/NotFound'
import LoadingCard from '../components/LoadingCard'

export default function RevenueUpdate() {
  const { t } = useTranslation()
  const { id } = useParams()

  const [redirectRevenue, setRedirectRevenue] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(0)
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()))
  const [amountRevenue, setAmountRevenue] = useState('')

  const [selectedID, setSelectedID] = useState('')

  const [listProduct, setListProduct] = useState([])
  const [selectedProductId, setSelectedProductId] = useState('')
  const [selectedProductUnit, setSelectedProductUnit] = useState('')

  const [listCompany, setListCompany] = useState([])

  const [isNotFound, setIsNotFound] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    try {
      const getInitialData = async () => {
        setIsLoading(true)

        const data = {}

        const optionsRevenueAndProductionLabels = {
          withCredentials: true,
          method: 'GET',
          url: `${process.env.REACT_APP_BASE_SERVER}/client-product-summary-lookup`
        }
        const optionsGetCompany = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),
          url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        }
        const dataID = {
          'id': id,
        }
        const optionsRevenue = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(dataID),
          url: `${process.env.REACT_APP_BASE_SERVER}/emission/revenue/read`
        }

        const [
          responseRevenueAndProductionLabels,
          responseGetCompany,
          responseRevenue,
        ] = await Promise.all([
          axios(optionsRevenueAndProductionLabels),
          axios(optionsGetCompany),
          axios(optionsRevenue)
        ])

        if (responseRevenueAndProductionLabels.data.ok) {
          setListProduct(responseRevenueAndProductionLabels.data.data.products)
        }

        if (responseGetCompany.data.success) {
          setListCompany(responseGetCompany.data.data)
        }

        if (responseRevenue.data.success) {
          if (
            Array.isArray(responseRevenue.data.data) &&
            responseRevenue.data.data.length > 0
          ) {
            var datePick = new Date(new Date(responseRevenue.data.data[0].revenue_date).getFullYear(), new Date(responseRevenue.data.data[0].revenue_date).getMonth(), 7)

            setSelectedID(id)
            setSelectedCompany(responseRevenue.data.data[0].revenue_company)
            setSelectedDate(datePick)
            setAmountRevenue(responseRevenue.data.data[0].revenue_amount)

            if (
              responseRevenueAndProductionLabels.data.ok &&
              Array.isArray(responseRevenueAndProductionLabels.data.data.products)
            ) {
              const tempSelectedProduct = responseRevenueAndProductionLabels.data.data.products
                .find(p => p.id === responseRevenue.data.data[0].revenue_product_id)
              setSelectedProductId(tempSelectedProduct?.id)
              setSelectedProductUnit(tempSelectedProduct?.unit)
            }
            setIsNotFound(false)
          } else {
            setIsNotFound(true)
          }
        }
        setIsLoading(false)
      }
      getInitialData()
    } catch (e) {
      // TODO: Handle error
    }
  }, [id])

  const onSelectCompany = async (e) => {
    let companyValue = e.target.value
    setSelectedCompany(companyValue)
  }

  const onSelectProduct = (e) => {
    let productValue = e.target.value
    const findSelectedProduct = listProduct.find(p => p.id === productValue)

    setSelectedProductId(findSelectedProduct.id)
    setSelectedProductUnit(findSelectedProduct.unit)
  }

  const onSelectDate = (e) => {
    var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7)
    setSelectedDate(datePick)
  }


  const onInputRevenue = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      setAmountRevenue(values.value)

    }
  }

  const onSaveRecord = async (e) => {
    try {
      let company = selectedCompany
      let revenue = amountRevenue
      if (revenue != "" && revenue != 0 && company > 0) {

        const data = {
          'company': selectedCompany,
          'date': selectedDate,
          'amount': amountRevenue,
          'id': selectedID,
          'productId': selectedProductId,
        }

        const options = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),
          url: `${process.env.REACT_APP_BASE_SERVER}/emission/revenue/update`
        }
        const response = await axios(options)
        console.log(response.data.ok, 'respet')
        if (response.data.ok) {
          Swal.fire({
            title: '',
            html: `<p>${t('dialog.dataUpdatedSuccessfully')}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              setRedirectRevenue(true)
            }
          })
        }
      } else {
        Swal.fire({
          title: '',
          html: `<p>${t('onboard.error.pleaseFill')}</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
  }

  if (redirectRevenue) {
    return <Navigate to={'/revenue'} />
  }

  let optionCompany = []
  optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
  // eslint-disable-next-line
  if (listCompany != undefined) {
    if (listCompany.length > 0) {
      listCompany.map(async (item, index) => {
        optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
      })
    }
  }

  let optionProduct = []
  optionProduct.push(selectedProductId ? null : <option key={0} value={0}>{t('emission.form.select')}</option>)
  // eslint-disable-next-line
  if (listProduct != undefined) {
    if (listProduct.length > 0) {
      listProduct.map(async (item) => {
        optionProduct.push(<option key={item.id} value={item.id}>{item.name}</option>)
      })
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t("menu.label22")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('general.btnUpdate')}
                </h4>
              </div>

              {
                isLoading ? (
                  <div className="card-body">
                    <LoadingCard />
                  </div>
                ) : <>
                  {
                    isNotFound ? (
                      <div className="card-body">
                        <NotFound />
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="company" className="form-label">
                                {t('emission.form.label1')} <span className="text-danger">*</span>
                              </label>
                              <select
                                onChange={e => onSelectCompany(e)}
                                value={selectedCompany}
                                id="company"
                                name="company"
                                className="form-select mb-3"
                              >
                                {optionCompany}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="date" className="form-label">
                                {t('emission.form.label4')} <span className="text-danger">*</span>
                              </label>
                              <div>
                                <DatePicker
                                  id="date"
                                  selected={selectedDate}
                                  onChange={(date) => onSelectDate(date)}
                                  dateFormat="MMMM yyyy"
                                  className="form-select mb-3"
                                  showMonthYearPicker
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="productName" className="form-label">
                                {t('setting.revenueAndProduction.label')} <span className="text-danger">*</span>
                              </label>
                              <select
                                onChange={e => onSelectProduct(e)}
                                value={selectedProductId}
                                id="productName"
                                name="productName"
                                className="form-select mb-3"
                              >
                                {optionProduct}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="revenue" className="form-label">
                                {t('emission.table.label5')} <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <InputNumeric
                                  value={amountRevenue}
                                  id="revenue"
                                  name="revenue"
                                  placeholder={t('emission.table.label5')}
                                  onValueChange={onInputRevenue}
                                />
                                {selectedProductUnit && (
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    {selectedProductUnit}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="text-end mt-3">
                              <button
                                onClick={onSaveRecord}
                                type="submit"
                                className="btn btn-primary"
                              >
                                {t('general.btnSave')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}