import { useTranslation } from 'react-i18next'

export default function BarChartLegend({
  series,
  filterSeries,
  hiddenSeries,
}) {
  const { i18n } = useTranslation()
  let className = ''
  let style
  if (series.length <= 3) {
    className = 'd-flex justify-content-center gap-2 pb-3 px-3'
  } else {
    className = 'pb-3 px-3 gap-2'
    style = {
      display: 'grid',
      gridTemplateColumns: `repeat(${series.length < 3 ? series.length : 3}, 1fr)`,
      gap: '0.5rem',
      padding: 0,
      margin: '0px auto',
      alignSelf: 'start',
    }
  }
  return (
    <div
      className={className}
      style={style}
    >
      {
        series.map((item, index) => {
          return (
            <div
              key={index}
              className='d-flex gap-2 cursor-pointer'
              onClick={() => filterSeries(item?.name)}
            >
              <div
                style={{
                  backgroundColor: hiddenSeries?.includes(item?.name)
                    ? 'transparent'
                    : item.color,
                  border: hiddenSeries?.includes(item?.name)
                    ? `1px solid ${item.color}`
                    : 'none',
                  borderRadius: '4px',
                  height: 12,
                  marginTop: 2,
                  minHeight: 12,
                  minWidth: 12,
                  width: 12,
                }}
              >
              </div>
              <p
                className='flex-grow-1 m-0'
                style={{ fontSize: 10 }}
              >
                {i18n.language === 'id' ? item?.nameId || item?.name : item?.name}
              </p>
            </div>
          )
        })
      }
    </div >
  )

}