import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  useMutation,
  useQuery,
} from "@tanstack/react-query"
import { useTranslation } from 'react-i18next'
import axios from "axios"
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import SimpleBar from 'simplebar-react'

import InputNumeric from '../../components/InputNumeric'
import LoadingCard from '../../components/LoadingCard'

import styles from './SettingScenario.module.css'

const useCarbonIntensitySettingSubmission = () => {
  return useMutation({
    mutationKey: ['carbon-intensity-setting-submission'],
    retry: false,
    mutationFn: async (data) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/carbon-intensity-setting-submission',
      ].join(''),
      method: 'POST',
      withCredentials: true,
      data,
    }).then(response => {
      return response?.data?.data ?? []
    }),
  })
}

const useCarbonIntensitySettingLookup = () => {
  return useQuery({
    queryKey: [
      'carbon-intensity-setting-summaries-lookup',
    ],
    retry: false,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/carbon-intensity-setting-summaries-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return response?.data?.data ?? {}
    }),
  })
}

const TootipInputWrapper = ({
  showTooltip,
  tooltipTitle,
  children,
}) => {
  if (showTooltip) {
    const inputWrapperProps = {}
    inputWrapperProps['data-bs-toggle'] = 'tooltip'
    inputWrapperProps['data-bs-trigger'] = 'hover'
    inputWrapperProps['data-bs-placement'] = 'top'
    inputWrapperProps['title'] = tooltipTitle
    return (
      <div {...inputWrapperProps} style={{ width: '100%' }}>
        {children}
      </div>
    )
  } else {
    return children
  }
}

function SettingCarbonIntensity() {
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const [isInitialDataEmpty, setIsInitialDataEmpty] = useState(false)

  const simpleBarRef = useRef(null)

  const query = useCarbonIntensitySettingLookup()

  const {
    mutate,
    isPending,
  } = useCarbonIntensitySettingSubmission()

  useEffect(() => {
    if (query.data) {
      setIsInitialDataEmpty(query.data.length === 0)
      const sortedItems = query.data
        .map(responseData => {
          return {
            ...responseData,
            data: [...responseData.data]
              .sort(function (a, b) {
                return a.year - b.year
              }),
          }
        })
      setItems(sortedItems)
    }
  }, [query.data])

  const onUpdateItem = async () => {
    if (items.length === 0 && isInitialDataEmpty) {
      Swal.fire({
        title: '',
        html: `<p>${t('setting.ci.error.pleaseFill')}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    } else {
      mutate({
        items
      }, {
        onSuccess: () => {
          Swal.fire({
            title: '',
            html: `<p>${isInitialDataEmpty ? t('dialog.dataRecordedSuccessfully') : t('dialog.dataUpdatedSuccessfully')}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              query.refetch()
            }
          })
        },
        onError: (error) => {
          let message
          if (typeof error.response?.data?.message === 'string') {
            message = error.response.data.message
          } else if (error.message) {
            message = error.message
          }
          Swal.fire({
            title: '',
            html: `<p>${message}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
            icon: 'error',
          })
        }
      })
    }
  }

  const onTickItem = (e, editedItemIndex) => {
    setItems(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedItemIndex]
      return newPrev.toSpliced(editedItemIndex, 1, {
        ...findEditedTargetByIndex,
        display: e.target.checked ? 1 : 0,
      })
    })
  }

  const onInputItem = (e, editedItemIndex) => {
    setItems(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedItemIndex]
      return newPrev.toSpliced(editedItemIndex, 1, {
        ...findEditedTargetByIndex,
        label: e.target.value,
      })
    })
  }

  const onInputRemarks = (e, editedItemIndex, editedTargetIndex) => {
    setItems(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedItemIndex]
      return newPrev.toSpliced(editedItemIndex, 1, {
        ...findEditedTargetByIndex,
        data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
          ...findEditedTargetByIndex.data[editedTargetIndex],
          remarks: e.target.value,
        }),
      })
    })
  }

  const onInputPercentage = (values, sourceInfo, editedItemIndex, editedTargetIndex) => {
    if (sourceInfo?.event?.target?.name && values) {
      setItems(prev => {
        const newPrev = [...prev]
        const findEditedTargetByIndex = prev[editedItemIndex]
        return newPrev.toSpliced(editedItemIndex, 1, {
          ...findEditedTargetByIndex,
          data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
            ...findEditedTargetByIndex.data[editedTargetIndex],
            value: values.value,
          }),
        })
      })
    }
  }

  const onSelectDate = (fullDate, editedItemIndex, editedTargetIndex) => {
    setItems(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedItemIndex]
      return newPrev.toSpliced(editedItemIndex, 1, {
        ...findEditedTargetByIndex,
        data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
          ...findEditedTargetByIndex.data[editedTargetIndex],
          year: new Date(fullDate).getFullYear(),
        }),
      })
    })
  }

  const onAddNewItem = () => {
    setItems(prev => {
      const id = prev.length > 0 ? prev[prev.length - 1].id + 1 : 1
      return [
        ...prev,
        {
          id,
          label: '',
          data: [
            {
              id: 1,
              remarks: '',
              value: '',
              year: '',
            },
          ],
        },
      ]
    })
  }

  const onAddMoreRow = (prevRowItemIndex, prevRowTargetIndex) => {
    setItems(prev => {
      const findPrevRowTargetByIndex = prev[prevRowItemIndex]
      return prev.toSpliced(prevRowItemIndex, 1, {
        ...findPrevRowTargetByIndex,
        data: findPrevRowTargetByIndex.data.toSpliced(prevRowTargetIndex + 1, 0, {
          id: findPrevRowTargetByIndex.data.length + 1,
          remarks: '',
          value: '',
          year: '',
        }),
      })
    })
  }

  const onDeleteRow = (currentItemIndex, currentTargetIndex) => {
    setItems(prev => {
      const findRowTargetByIndex = prev[currentItemIndex]
      if (currentTargetIndex === 0 && findRowTargetByIndex.data.length === 1) {
        return prev.toSpliced(currentItemIndex, 1)
      } else {
        return prev.toSpliced(currentItemIndex, 1, {
          ...findRowTargetByIndex,
          data: findRowTargetByIndex.data.toSpliced(currentTargetIndex, 1),
        })
      }
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t('menu.label6')}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">
                  {t('setting.ci.intensityBasedEmissionTarget')}
                </h5>
              </div>

              <div className="card-body">
                {
                  !query.isFetching && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '0.188rem',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <i className="ri-lightbulb-line" style={{ color: 'orange' }}></i>
                      <p className="text-muted">
                        <span className="mr-1">
                          {t('setting.ci.tickInfo')}
                        </span>
                      </p>
                    </div>
                  )}

                <SimpleBar
                  id="scrollbar"
                  // Issue: https://github.com/Grsmto/simplebar/issues/476#issuecomment-642779891
                  classNames={{
                    scrollbar: 'simplebar-scrollbar-custom',
                  }}
                  ref={simpleBarRef}
                >
                  {
                    query.isFetching ? (
                      <LoadingCard />
                    ) : (
                      <>
                        {
                          items.length > 0 && items.map((item, itemIndex) => {
                            return (
                              <Fragment key={item.id}>
                                <div className={styles.gridContainer}>
                                  <div className={styles.group}>
                                    {
                                      itemIndex === 0 && (
                                        <div className='d-flex bg-white gap-2'>
                                          <div
                                            className="form-check"
                                            data-bs-toggle='tooltip'
                                            data-bs-trigger='hover'
                                            data-bs-placement='top'
                                            title={t('setting.ci.tickInfo')}
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              style={{ visibility: 'hidden' }}
                                            >
                                            </input>

                                          </div>
                                          <label
                                            htmlFor="targetLabel"
                                            className="form-label ml-2"
                                          >
                                            {t('setting.ci.targetLabel')} <span className="text-danger">*</span>
                                          </label>
                                        </div>
                                      )
                                    }
                                    <div className={styles.gridBody}>
                                      <div className='d-flex gap-1'>
                                        <div
                                          className="form-check"
                                          data-bs-toggle='tooltip'
                                          data-bs-trigger='hover'
                                          data-bs-placement='top'
                                          title={t('setting.ci.tickInfo')}
                                          style={{ alignSelf: 'center' }}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={!!item.display}
                                            onChange={(e) => onTickItem(e, itemIndex)}
                                            value={!!item.display}
                                          >
                                          </input>
                                        </div>
                                        <TootipInputWrapper
                                          tooltipTitle={item.label}
                                        >
                                          <input
                                            value={item.label}
                                            onChange={e => onInputItem(e, itemIndex)}
                                            className="form-control"
                                            placeholder={t('setting.ci.name')}
                                            autoFocus={(itemIndex === items.length - 1)}
                                          />
                                        </TootipInputWrapper>

                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.note}>
                                    {
                                      itemIndex === 0 && (
                                        <div className='d-flex bg-white'>
                                          <label
                                            htmlFor="remarks"
                                            className="form-label"
                                          >
                                            {t('setting.scenario.remarks')}
                                          </label>
                                        </div>
                                      )
                                    }
                                    <div
                                      className={styles.gridBody}
                                      style={{
                                        marginBottom: '1rem',
                                      }}
                                    >
                                      {
                                        item.data
                                          .map((target, targetIndex) => {
                                            return (
                                              <TootipInputWrapper
                                                key={`${itemIndex}-${targetIndex}`}
                                                tooltipTitle={target.remarks}
                                              >
                                                <div className="input-group">
                                                  <input
                                                    value={target.remarks}
                                                    onChange={e => onInputRemarks(e, itemIndex, targetIndex)}
                                                    className="form-control"
                                                    placeholder={t('setting.scenario.notesOrComments')}
                                                  />
                                                </div>
                                              </TootipInputWrapper>
                                            )
                                          })
                                      }
                                    </div>
                                  </div>
                                  <div className={styles.year}>
                                    {
                                      itemIndex === 0 && (
                                        <div className='d-flex bg-white'>
                                          <label
                                            htmlFor="year"
                                            className="form-label"
                                          >
                                            {t('setting.scenario.year')} <span className="text-danger">*</span>
                                          </label>
                                        </div>
                                      )
                                    }
                                    <div className={styles.gridBody}>
                                      {
                                        item.data
                                          .map((target, targetIndex) => {
                                            const datePickerProps = {
                                              id: "date",
                                              onChange: (e) => onSelectDate(e, itemIndex, targetIndex),
                                              dateFormat: "yyyy",
                                              className: "form-select",
                                              showYearPicker: true,
                                              placeholderText: 'YYYY',
                                              popperPlacement: 'top',
                                              popperClassName: "trucount-popper-date-picker-class",
                                              // https://stackoverflow.com/a/75459277
                                              popperProps: {
                                                strategy: "fixed",
                                              },
                                            }
                                            if (target.year) {
                                              datePickerProps.selected = new Date(`${target.year}/01/01`)
                                            }
                                            const datePickerWrapperProps = {
                                              key: `${itemIndex}-${targetIndex}`,
                                            }
                                            return (
                                              <div {...datePickerWrapperProps}>
                                                <DatePicker
                                                  {...datePickerProps}
                                                />
                                              </div>
                                            )
                                          })}

                                    </div>

                                  </div>
                                  <div className={styles.target}>
                                    {
                                      itemIndex === 0 && (
                                        <div className='d-flex bg-white'>

                                          <label
                                            htmlFor="value"
                                            className="form-label"
                                          >
                                            Target (%) <span className="text-danger">*</span>
                                          </label>
                                        </div>
                                      )
                                    }
                                    <div className={styles.gridBody}>
                                      {
                                        item.data
                                          .map((target, targetIndex) => {
                                            return (
                                              <TootipInputWrapper
                                                key={`${itemIndex}-${targetIndex}`}
                                                tooltipTitle={target.value}
                                              >
                                                <InputNumeric
                                                  value={target.value}
                                                  id={`target-${itemIndex}-${targetIndex}`}
                                                  name={`target-${itemIndex}-${targetIndex}`}
                                                  placeholder={t('setting.scenario.percentage')}
                                                  onValueChange={(values, sourceInfo) => {
                                                    onInputPercentage(values, sourceInfo, itemIndex, targetIndex)
                                                  }}
                                                />
                                              </TootipInputWrapper>
                                            )
                                          })
                                      }
                                    </div>
                                  </div>
                                  <div className={styles.action}>
                                    <div className='d-flex flex-column'>
                                      {
                                        itemIndex === 0 && (
                                          <div className='d-flex bg-white'>

                                            <label
                                              htmlFor="action"
                                              className="form-label"
                                            >
                                              {t('setting.scenario.action')}
                                            </label>
                                          </div>
                                        )
                                      }
                                      <div className={styles.gridBody}>
                                        {
                                          item.data
                                            .map((_target, targetIndex) => {
                                              return <div
                                                key={`${itemIndex}-${targetIndex}`}
                                                className='d-flex gap-2'
                                              >
                                                <button
                                                  onClick={() => onDeleteRow(itemIndex, targetIndex)}
                                                  type="button"
                                                  className="btn btn-icon btn-soft-danger"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={t('setting.scenario.deleteRow')}
                                                >
                                                  <i className="ri-delete-bin-5-line"></i>
                                                </button>

                                                <button
                                                  onClick={() => onAddMoreRow(itemIndex, targetIndex)}
                                                  type="button"
                                                  className="btn btn-icon btn-soft-primary"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={t('setting.scenario.addMoreRow')}
                                                  disabled={true}
                                                >
                                                  <i className=" ri-add-circle-line"></i>
                                                </button>
                                              </div>
                                            })
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mb-3'></div>
                              </Fragment>
                            )
                          })
                        }
                      </>
                    )}
                </SimpleBar>

                {
                  !query.isFetching && (
                    <>
                      <div className="col-lg-12">
                        <div>
                          <div>
                            <button
                              onClick={onAddNewItem}
                              type="button"
                              className="btn btn-soft-success mb-5"
                              style={{ width: '100%' }}
                              disabled={isPending}
                            >
                              + {t('setting.ci.addNewIntensityBasedEmissionTarget')}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="text-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onUpdateItem}
                            disabled={isPending || items.length === 0}
                          >
                            {
                              isInitialDataEmpty
                                ? isPending ? t('general.btnSaving') : t('general.btnSave')
                                : isPending ? t('general.btnUpdating') : t('general.btnUpdate')}
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SettingCarbonIntensity