import { useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import {
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
} from '../../library/helper'

export default function EmptyBarChart() {
  const { i18n } = useTranslation()

  const [series] = useState([
    {
      "name": "Series-1",
      "nameId": "Series-1",
      "data": [
        18,
        13,
        15,
        6.57,
        0.87,
        3.2,
        1,
        7,
        6.5,
        9,
        12,
        6,
      ]
    },
  ])

  const emptyOptions = {
    chart: {
      id: 'chartBarEmpty',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    annotations: {
      text: 'No Data Available',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    colors: EMPTY_CHART_COLORS,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: i18n.language === 'id'
        ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des']
        : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
      tickAmount: 3,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return twoDecimalFormat(val, i18n.language) + " tCO₂e"
        },
      },
      enabled: false,
    },
  }

  return (
    <ApexCharts
      options={emptyOptions}
      series={series}
      type="bar"
      height={350}
    />
  );
}
