export const hidePreloader = () => {
  document.getElementById('preloader').setAttribute("style", "display:none");
}

export const showPreloader = () => {
  document.getElementById('preloader').setAttribute("style", "display:block");
}

export const resetHeaderLayout = () => {
  document.documentElement.setAttribute("data-layout", "vertical")
  document.documentElement.setAttribute("data-topbar", "light")
}

export const horizontalHeaderLayout = () => {
  document.documentElement.setAttribute("data-layout", "horizontal")
  document.documentElement.setAttribute("data-topbar", "dark")
}

export const priceFormat = (harga) => {
  // eslint-disable-next-line
  if (harga == '0' || harga == null || harga == undefined) return '0';

  let price = harga.toString().split('.');
  let reverse = price[0].toString().split('').reverse().join('');
  let ribuan = reverse.match(/\d{1,3}/g);
  ribuan = ribuan.join(',').split('').reverse().join('');

  if (harga < 0) {
    if (price.length > 1) return `-${ribuan}.${price[1]}`;
    else return `-${ribuan}`;
  } else {
    if (price.length > 1) return `${ribuan}.${price[1]}`;
    else return ribuan;
  }
}

export const percentageFormat = (number, locales) => {
  return number.toLocaleString(locales, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })
}

export const threeDecimalFormat = (number, locales) => {
  return number.toLocaleString(locales, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
}

export const twoDecimalFormat = (number, language) => {
  if (isNaN(number)) {
    return null
  } else {
    let locales
    if (language === 'id') {
      locales = 'id-ID'
    } else {
      locales = 'en-US'
    }
    return number.toLocaleString(locales, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}

export const decimalFormat = (number, locales) => {
  return number.toLocaleString(locales)
}

export const integerFormat = (number, language) => {
  if (isNaN(number)) {
    return null
  } else {
    let locales
    if (language === 'id') {
      locales = 'id-ID'
    } else {
      locales = 'en-US'
    }
    return number.toLocaleString(locales, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }
}

export const dateSortingFn = (rowA, rowB) => {
  const a = rowA.original.record_date
  const b = rowB.original.record_date

  if (a > b) {
    return 1
  } else if (b > a) {
    return -1
  } else {
    return 0
  }
}

export const ROUTE_PATHS = {
  activity: '/activity',
  analytics: '/analytics',
  analyticsCompletion: '/analytics/completeness',
  analyticsLocation: '/analytics/location',
  analyticsLocationSummary: '/analytics/location/summary',
  analyticsCompany: '/analytics/company',
  analyticsCarbonIntensity: '/analytics/carbon-intensity',
  company: '/company',
  companyUpdateById: '/company/update/:id',
  companyCreate: '/company/create',
  dashboard: '/dashboard',
  division: '/division',
  divisionUpdateById: '/division/update/:id',
  divisionCreate: '/division/create',
  emission: '/emission',
  emissionUpdateById: '/emission/update/:id',
  emissionCreate: '/emission/create',
  emissionCreateInBulk: '/emission/create-in-bulk',
  location: '/location',
  locationUpdateById: '/location/update/:id',
  locationCreate: '/location/create',
  onboard: '/onboard',
  program: '/program',
  reduction: '/reduction',
  reductionCreate: '/reduction/create',
  removal: '/removal',
  removalUpdateById: '/removal/update/:id',
  removalCreate: '/removal/create',
  report: '/report',
  reportIso: '/report/iso',
  reportGhg: '/report/ghg',
  reportGri: '/report/gri',
  reportSummary: '/report/summary',
  revenue: '/revenue',
  revenueUpdateById: '/revenue/update/:id',
  revenueCreate: '/revenue/create',
  setting: '/setting',
  settingScenario: '/setting/scenario',
  settingCarbonIntensity: '/setting/carbon-intensity',
  settingRevenue: '/setting/advanced/revenue',
  settingRevenueUpdateById: '/setting/advanced/revenue/update/:id',
  settingRevenueCreate: '/setting/advanced/revenue/create',
  scenario: '/scenario',
  scope3category1: '/scope3/category1',
  scope3category1Create: '/scope3/category1/create',
  scope3category1Update: '/scope3/category1/update/:id',
  scope3category2: '/scope3/category2',
  scope3category2Create: '/scope3/category2/create',
  scope3category2Update: '/scope3/category2/update/:id',
  scope3category3: '/scope3/category3',
  scope3category4: '/scope3/category4',
  scope3category4Create: '/scope3/category4/create',
  scope3category4Update: '/scope3/category4/update/:id',
  scope3category5: '/scope3/category5',
  scope3category6: '/scope3/category6',
  scope3category6Create: '/scope3/category6/create',
  scope3category6Update: '/scope3/category6/update/:id',
  scope3category7: '/scope3/category7',
  scope3category7Create: '/scope3/category7/create',
  scope3category7Update: '/scope3/category7/update/:id',
  scope3category8: '/scope3/category8',
  scope3category9: '/scope3/category9',
  scope3category9Create: '/scope3/category9/create',
  scope3category9Update: '/scope3/category9/update/:id',
  scope3category10: '/scope3/category10',
  scope3category11: '/scope3/category11',
  scope3category11Create: '/scope3/category11/create',
  scope3category11Update: '/scope3/category11/update/:id',
  scope3category12: '/scope3/category12',
  scope3category13: '/scope3/category13',
  scope3category14: '/scope3/category14',
  scope3category15: '/scope3/category15',
  scope3category15Create: '/scope3/category15/create',
  scope3category15Update: '/scope3/category15/update/:id',
  investments: '/investments',
  investmentsUpdateById: '/investments/update/:id',
  investmentsCreate: '/investments/create',
  user: '/user',
  userUpdateById: '/user/update/:id',
  userCreate: '/user/create',
  profile: '/profile',
  analyticsSBTi: '/analytics/science-based-targets-initiative',
}

// TODO: Add more colors if one client has more than 23 recorded emission client categories
export const CHART_COLORS = [
  '#E17E7F',
  '#0A564B',
  '#FADF70',
  '#81E5BA',
  '#DD9E37',
  '#73B5F7',
  '#438375',
  '#FFDE59',
  '#EF643A',
  '#E27F7F',
  '#2D416A',
  '#E99386',
  '#448375',
  '#62B4AD',
  '#8CAFA4',
  '#043333',
  '#2E89BC',
  '#E27F7F',
  '#438375',
  '#FFDE59',
  '#E99486',
  '#09564B',
  '#E27F7F',
  '#438375',
  '#FFDE59',
  '#E99486',
  '#09564B',
  '#E27F7F',
  '#438375',
  '#FFDE59',
]

export const EMPTY_CHART_COLORS = [
  '#999999',
  '#777777',
  '#555555',
  '#333333',
  '#111111',
  '#999999',
  '#777777',
  '#555555',
  '#333333',
  '#111111',
  '#999999',
  '#777777',
  '#555555',
  '#333333',
  '#111111',
  '#999999',
  '#777777',
  '#555555',
  '#333333',
  '#111111',
]

export const AREA_CHART_COLORS = [
  ...CHART_COLORS,
]

export const SCENARIO_LOCAL_STORAGE_KEY = 'scenarioCheckedIds'

export const ANALYTICS_LABEL = {
  timescale: 'timescale',
  emission: 'actual emission - reduction',
  carbonIntensity: 'carbon intensity',
  target: 'target',
  projection: 'emission forecast',
  actualEmission: 'actual emission',
  inProgressEmission: 'in progress emission',
  nearTermMarker: 'near term marker',
  longTermMarker: 'long term marker',
  emissionPathway: 'emission goals',
  nearTermSBTs: 'Near-Term SBTi',
  longTermSBTs: 'Long-Term SBTi',
}