import React, {
  useEffect,
  useState,
  useMemo,
} from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from "react-redux"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { decrement, increment } from "../features/counter/counterSlice"

import axios from "axios"
import qs from 'qs'
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'

import { useTranslation } from 'react-i18next'
import ExcelJS from 'exceljs'

import { hidePreloader, showPreloader } from '../library/helper'

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import "react-datepicker/dist/react-datepicker.css"

function EmissionCreateInBulk(props) {
  const [redirectEmission, setRedirectEmission] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(0)
  const [selectedOffice, setSelectedOffice] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [listEmissionRecord, setListEmissionRecord] = useState([
    {
      assets: "",
      factor: 0,
      amount: 0,
      amount_2: 0,
      amount_3: 0,
      amount_4: 0,
      amount_5: 0,
      amount_6: 0,
      amount_7: 0,
      amount_8: 0,
      unit: 0,
      multiplier: 1,
      reduction: 0,
      factorCompare: 0,
    },
  ])
  const [listCompany, setListCompany] = useState([])
  const [listOffice, setListOffice] = useState([])
  const [listCategory, setListCategory] = useState([])
  const [listEmissionFactor, setListEmissionFactor] = useState([])
  const [listEmissionFactorUnit, setListEmissionFactorUnit] = useState([])
  const [selectedCategoryTooltip, setSelectedCategoryTooltip] = useState('')
  const [selectedFile, setSelectedFile] = useState()
  const translation = useTranslation()
  const selectedLanguage = translation.i18n.language;
  const [limitDate, setLimitDate] = useState('01-01-2019')

  const [tourSteps, setTourSteps] = useState([])
  const [tourRun, setTourRun] = useState(false)
  const [tourStepIndex, setTourStepIndex] = useState(0)

  const tourInitialSteps = useMemo(
    () => {
      return [
        {
          content: translation.t('emission.tour.company2'),
          spotlightClicks: true,
          disableBeacon: true,
          target: '#tour__company',
          title: translation.t('emission.tour.company1'),
        },
        {
          spotlightClicks: true,
          target: '#tour__location',
          title: translation.t('emission.tour.location'),
        },
        {
          content: translation.t('emission.tour.source2'),
          spotlightClicks: true,
          target: '#tour__emission_source',
          title: translation.t('emission.tour.source1'),
        },
        {
          spotlightClicks: true,
          target: '#tour__type',
          title: translation.t('emission.tour.fuel'),
        },
        {
          spotlightClicks: true,
          target: '#tour__unit',
          title: translation.t('emission.tour.unit.fuel'),
        },
        {
          spotlightClicks: true,
          target: '#tour__template',
          title: translation.t('emission.tour.template'),
        },
        {
          spotlightClicks: true,
          target: '#tour__instruction',
          title: translation.t('emission.tour.instruction'),
        },
        {
          spotlightClicks: true,
          target: '#tour__attachment',
          title: translation.t('emission.tour.attachment.button'),
        },
        {
          target: '#tour__save',
          title: translation.t('emission.tour.save.bulk'),
        },
      ]
    }, [translation.i18n.language])

  useEffect(() => {
    setTourSteps(tourInitialSteps)
  }, [tourInitialSteps])


  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED].includes(status))) {
      // Need to set our running state to false, so we can restart if we click start again.
      setTourRun(false)
      setTourStepIndex(0)
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      setTourStepIndex(index - 1)
    } else if (type === EVENTS.STEP_AFTER) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourStepIndex(nextStepIndex)
    }
  }

  useEffect(() => {
    (async () => {
      showPreloader();

      setLimitDate("01-01-" + (new Date().getFullYear() - 2))

      const optionsGetCompany = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
      };
      const responseGetCompany = await axios(optionsGetCompany);
      if (responseGetCompany.data.success) {
        setListCompany(responseGetCompany.data.data)
      }

      const optionsGetEmissionCategory = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
      };
      const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
      if (responseGetEmissionCategory.data.success) {
        setListCategory(responseGetEmissionCategory.data.data)
      }

      const optionsGetEmissionFactor = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
      };
      const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
      if (responseGetEmissionFactor.data.success) {
        setListEmissionFactor(responseGetEmissionFactor.data.data)
      }

      const optionsGetEmissionFactorUnit = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
      };
      const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
      if (responseGetEmissionFactorUnit.data.success) {
        setListEmissionFactorUnit(responseGetEmissionFactorUnit.data.data)
      }

      if (translation.i18n.language === "en") {
        setSelectedCategoryTooltip("Please select category")
      } else {
        setSelectedCategoryTooltip("Pilih kategori emisi")
      }
      hidePreloader();
    })()

  }, [translation.i18n.language])

  const onSelectCompany = async (e) => {
    const companyValue = e.target.value;
    const data = { 'coid': companyValue }
    const optionsGetOffice = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
    }
    const responseGetOffice = await axios(optionsGetOffice);
    if (responseGetOffice.data.success) {
      setSelectedCompany(companyValue)
      setListOffice(responseGetOffice.data.data)
      setSelectedOffice(0)
    }
  }

  const onSelectCategory = (e) => {
    const categoryValue = parseInt(e.target.value)
    setSelectedCategory(categoryValue)

    const selectedLanguage = translation.i18n.language
    const category = listCategory.find(({ category_id }) => {
      return category_id === categoryValue
    })
    setSelectedCategoryTooltip(
      selectedLanguage === "en"
        ? category.category_description
        : category.category_description_id
    )

    setListEmissionRecord([
      {
        assets: "",
        factor: 0,
        amount: "",
        unit: 0,
        multiplier: 1,
      },
    ])

    // Update tour steps
    if (!!categoryValue) {
      const efs = listEmissionFactor
        .filter(item => item.cf_category === categoryValue)
      let newSteps = tourInitialSteps
      const startIndex = 3
      if (efs.length > 0) {
        let typeAtStartIndex = newSteps[startIndex].title
        let unitAfterStartIndex = newSteps[startIndex + 1].title
        if (
          categoryValue === 22 ||
          categoryValue === 21 ||
          categoryValue === 20
        ) {
          typeAtStartIndex = translation.t('emission.tour.gasType')
          unitAfterStartIndex = translation.t('emission.tour.unit.gasType')
        } else if (categoryValue === 26) {
          typeAtStartIndex = translation.t('emission.tour.treatmentType')
          unitAfterStartIndex = translation.t('emission.tour.unit.volumeOfWastewater')
        } else if (categoryValue === 25) {
          typeAtStartIndex = translation.t('emission.tour.mineDepth')
          unitAfterStartIndex = translation.t('emission.tour.unit.cmm')
        } else if (categoryValue === 18) {
          typeAtStartIndex = translation.t('emission.tour.fireExtinguisher')
          unitAfterStartIndex = translation.t('emission.tour.unit.fireExtinguisher')
        } if (categoryValue === 7) {
          typeAtStartIndex = translation.t('emission.tour.grid')
          unitAfterStartIndex = translation.t('emission.tour.unit.grid')
        } else if (categoryValue === 4) {
          typeAtStartIndex = translation.t('emission.tour.treatmentType')
          unitAfterStartIndex = translation.t('emission.tour.unit.treatmentType')
        } else if (categoryValue === 3) {
          typeAtStartIndex = translation.t('emission.tour.refrigerant')
          unitAfterStartIndex = translation.t('emission.tour.unit.refrigerant')
        }
        setTourSteps(newSteps.toSpliced(startIndex, 2,
          {
            spotlightClicks: true,
            target: "#tour__type",
            title: typeAtStartIndex,
          },
          {
            spotlightClicks: true,
            target: "#tour__unit",
            title: unitAfterStartIndex,
          },
        ))
      } else {
        setTourSteps(newSteps.toSpliced(startIndex, 2))
      }
    }
  }

  const onSelectOffice = (e) => {
    setSelectedOffice(e.target.value)
  }

  const onSelectEmission = (e, index) => {
    const value = parseInt(e.target.value)
    setListEmissionRecord(current =>
      current.map((obj, i) => {
        if (index === i) {
          return { ...obj, factor: value }
        } else {
          return obj;
        }
      }),
    )
  }

  const onSelectUnit = (e, index) => {
    const filteredUnit = listEmissionFactorUnit.filter(
      item => item.unit_id === parseInt(e.target.value)
    )
    setListEmissionRecord(current =>
      current.map((obj, i) => {
        if (index === i) {
          return {
            ...obj,
            unit: e.target.value,
            multiplier: filteredUnit[0].unit_multiplier
          }
        } else {
          return obj;
        }
      }),
    )
  }

  const onSaveRecord = async (e) => {
    e.preventDefault()
    try {
      const filteredCategory = listCategory.filter(
        item => item.category_id === selectedCategory
      )
      const filteredEmissionFactor = listEmissionFactor.filter(item => {
        return (
          item.emission_factor_id === parseInt(listEmissionRecord[0].factor) &&
          item.cf_category === selectedCategory
        )
      })

      const data = new FormData();
      if (filteredEmissionFactor.length > 0) {
        data.append('scope', filteredCategory[0].category_scope)
        data.append('category', selectedCategory)
        data.append('factor', listEmissionRecord[0].factor)
        data.append('unit', listEmissionRecord[0].unit)
        data.append('company', selectedCompany)
        data.append('location', selectedOffice)
        data.append('attachment', selectedFile)
      } else {
        data.append('scope', filteredCategory[0].category_scope)
        data.append('category', selectedCategory)
        data.append('factor', listEmissionRecord[0].factor)
        data.append('unit', listEmissionRecord[0].unit)
        data.append('company', selectedCompany)
        data.append('location', selectedOffice)
        data.append('attachment', selectedFile)
      }

      const options = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: data,
        url: `${process.env.REACT_APP_BASE_SERVER}/emission/create-in-bulk`
      }
      const response = await axios(options)

      if (response.data.success) {
        Swal.fire({
          title: '',
          html: `<p>Successfully adding: ${response.data.affectedRows} new emission records with ${response.data.attachmentAffectedRows} attachments</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            setRedirectEmission(true)
          }
        })
      }
    } catch (e) {
      Swal.fire({
        title: '',
        html: `<p>${typeof e.response.data.message === 'string' ? e.response.data.message : 'Please fill all information needs'}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
  }

  const onAttachFile = () => {
    document.getElementById('formFile').click();
  }

  const onChangeFile = (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  const onDownloadTemplate = () => {
    // Create xlsx file
    const wb = new ExcelJS.Workbook()
    wb.creator = 'TruCount'
    wb.lastModifiedBy = 'Trucount System'
    wb.created = new Date()
    wb.modified = new Date()

    const sheetSetting = {
      pageSetup: {
        fitToPage: true,
        fitToHeight: 1,
        fitToWidth: 1,
      },
      views: [
        {
          state: 'frozen',
          xSplit: 1,
          ySplit: 1,
          activeCell: 'A2'
        },
      ],
    }
    const tabColor = '434343' // #434343
    const mainWs = wb.addWorksheet('Your Emission Records', {
      ...sheetSetting,
      properties: {
        tabColor: { argb: tabColor },
      }
    })
    const exampleWs = wb.addWorksheet('Example', sheetSetting)

    mainWs.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N"
    exampleWs.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N"

    const columns = [
      { header: 'Row Number', key: 'rowNumber', width: 11.8 },
      { header: 'Date', key: 'date', width: 10 },
      { header: 'Asset Name', key: 'name', width: 20.5 },
    ]
    // eslint-disable-next-line
    if (selectedCategory == 26) {
      columns.push(
        { header: 'Inlet COD (mg COD/m³)', key: 'amount_2', width: 21 },
        { header: 'Outlet COD (mg COD/m³)', key: 'amount_3', width: 21 },
        { header: 'Volume', key: 'amount', width: 11.7 },
        { header: 'Has Attachment', key: 'hasAttachment', width: 13 },
      )
    } else {
      columns.push(
        { header: 'Amount', key: 'amount', width: 11.7 },
        { header: 'Has Attachment', key: 'hasAttachment', width: 13 },
      )
    }

    const worksheets = [mainWs, exampleWs]

    worksheets.forEach(sheet => {
      sheet.columns = columns
      sheet.getRow(1).height = 16

      const headerCodes = ['A1', 'B1', 'C1',]
      // eslint-disable-next-line
      if (selectedCategory == 26) {
        headerCodes.push('D1', 'E1', 'F1', 'G1')
      } else {
        headerCodes.push('D1', 'E1')
      }

      headerCodes.forEach(cellCode => {
        sheet.getCell(cellCode).font = {
          color: { argb: 'FFFFFF' },
        }
        sheet.getCell(cellCode).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: tabColor },
        }
        sheet.getCell(cellCode).alignment = {
          vertical: 'middle', horizontal: 'left'
        }
      })

      // Row Number column
      sheet.dataValidations.add("A2:A9999", {
        type: 'decimal',
        allowBlank: true,
        operator: 'greaterThanOrEqual',
        formulae: [1],
        error: 'Please provide a numeric value that is greater than or equal to 1',
        errorTitle: 'Invalid Data',
        showErrorMessage: true,
      })
      // TODO: Add corrected validation for the date column
      let amountColumn = 'D'
      let attachmentColumn = 'E'
      // eslint-disable-next-line
      if (selectedCategory == 26) {
        // Inlet COD column and Outlet COD column
        ['D2:D9999', 'E2:E9999'].forEach((columnRowRange) => {
          sheet.dataValidations.add(columnRowRange, {
            type: 'decimal',
            allowBlank: true,
            operator: 'greaterThanOrEqual',
            formulae: [0],
            error: 'Please provide a numeric value that is greater than or equal to 0',
            errorTitle: 'Invalid Data Type',
            showErrorMessage: true,
          })
        })
        amountColumn = 'F'
        attachmentColumn = 'G'
      }
      // Amount column
      sheet.dataValidations.add(`${amountColumn}2:${amountColumn}9999`, {
        type: 'decimal',
        allowBlank: true,
        operator: 'greaterThanOrEqual',
        formulae: [0],
        error: 'Please provide a numeric value that is greater than or equal to 0',
        errorTitle: 'Invalid Data Type',
        showErrorMessage: true,
      })
      // Has Attachment column
      sheet.dataValidations.add(`${attachmentColumn}2:${attachmentColumn}9999`, {
        type: 'list',
        allowBlank: true,
        formulae: ['"Yes, No"'],
        error: 'Please use the drop down to select a valid value',
        errorTitle: 'Invalid Selection',
        showErrorMessage: true,
      })
    })

    // Fill example tab with some dummy data
    exampleWs
      .getRows(2, 4)
      .forEach((row, rowIndex) => {
        row.getCell('rowNumber').value = rowIndex + 1
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
        const formattedDate = `${year}-${month}`
        row.getCell('date').value = formattedDate
        row.getCell('name').value = 'Electricity Consumption'
        // eslint-disable-next-line
        if (selectedCategory == 26) {
          row.getCell('amount_2').value = 100
          row.getCell('amount_3').value = 30
          row.getCell('name').value = 'WWTP'
        }
        row.getCell('amount').value = 10066.889
        row.getCell('hasAttachment').value = rowIndex === 1 ? 'Yes' : ''
      })

    const fileName = `emission_record_trucount_${props.account?.client_name}`
    wb.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.download = `${fileName}.xlsx`
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  if (redirectEmission) {
    return <Navigate to={'/emission'} />
  } else {
    const optionCompany = [
      ...selectedCompany <= 0
        ? [<option key={0} value={0}>
          {translation.t('emission.form.placeholder1')}
        </option>]
        : [],
      ...listCompany.map((item) => {
        return <option key={item.company_id} value={item.company_id}>
          {item.company_name}
        </option>
      }),
    ]

    const optionOffice = [
      ...selectedOffice <= 0
        ? [<option key={0} value={0}>
          {translation.t('emission.form.placeholder2')}
        </option>]
        : [],
      ...listOffice.map((item) => {
        return <option key={item.office_id} value={item.office_id}>
          {item.office_name}
        </option>
      }),
    ]

    const optionCategory = [
      ...selectedCategory <= 0
        ? [<option key={0} value={0}>
          {translation.t('emission.form.placeholder3')}
        </option>]
        : [],
      ...listCategory.map((item) => {
        return <option key={item.category_id} value={item.category_id}>
          {selectedLanguage === "en" ? item.category_name : item.category_name_id}
        </option>
      }),
    ]


    const optionEmissionFactor = listEmissionFactor
      .filter(item => item.cf_category === selectedCategory)
      .map((item) => {
        return <option
          key={item.emission_factor_id}
          value={item.emission_factor_id}
        >
          {item.emission_name}
        </option>
      })

    return (
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="d-flex gap-3 mb-sm-0 align-items-baseline">
                  <h4 id="tour__header">
                    {translation.t("menu.label21")} - Scope 1 & 2
                  </h4>
                  <button
                    onClick={() => setTourRun(true)}
                    className="btn btn-outline-info btn-sm"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Start Guideline"
                  >
                    <i className="ri-question-fill"></i>
                  </button>
                  <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    run={tourRun}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    stepIndex={tourStepIndex}
                    steps={tourSteps}
                    styles={{
                      options: {
                        primaryColor: '#000',
                        width: 500,
                        zIndex: 10000,
                      }
                    }}
                    locale={{
                      back: translation.t('emission.tour.back'),
                      close: translation.t('emission.tour.close'),
                      last: translation.t('emission.tour.last'),
                      next: translation.t('emission.tour.next'),
                      open: translation.t('emission.tour.open'),
                      skip: translation.t('emission.tour.skip'),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {translation.t('general.btnCreateInBulk')}
                  </h4>
                </div>

                <div className="card-body">
                  <h5>{translation.t('emission.form.info1')}</h5>
                  <p className='mb-3'>{translation.t('emission.form.subInfo1')}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3" id="tour__company">
                        <label htmlFor="company" className="form-label">
                          {translation.t('emission.form.label1')}
                        </label>
                        <select
                          onChange={e => onSelectCompany(e)}
                          value={selectedCompany}
                          id="company"
                          name="company"
                          className="form-select mb-3"
                        >
                          {optionCompany}
                        </select>
                      </div>
                    </div>
                    {selectedCompany > 0 ? <div className="col-md-6">
                      <div className="mb-3" id="tour__location">
                        <label htmlFor="office" className="form-label">
                          {translation.t('emission.table.label2')}
                        </label>
                        <select
                          onChange={e => onSelectOffice(e)}
                          value={selectedOffice}
                          id="company"
                          name="company"
                          className="form-select mb-3"
                        >
                          {optionOffice}
                        </select>
                      </div>
                    </div> : ""}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div id="tour__emission_source">
                        <label htmlFor="category" className="form-label">{translation.t('emission.form.label2')}</label>
                        <div className="input-group">
                          <select onChange={e => onSelectCategory(e)} value={selectedCategory} id="category" name="category" className="form-select">
                            {optionCategory}
                          </select>
                          <span className="input-group-text" id="basic-addon2" title={selectedCategoryTooltip}>
                            <i className="ri-information-line"></i>
                          </span>
                        </div>
                        {
                          // eslint-disable-next-line
                          selectedCategory == 25
                            ? <p>&nbsp;{translation.t('emission.form.label3')} :
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_Chapter_07_Supplementary_Material.pdf"
                              >
                                &nbsp;IPCC AR6 2023
                              </a>
                            </p>
                            // eslint-disable-next-line
                            : selectedCategory == 7
                              ? <p>&nbsp;{translation.t('emission.form.label3')} :
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://gatrik.esdm.go.id/assets/uploads/download_index/files/96d7c-nilai-fe-grk-sistem-ketenagalistrikan-tahun-2019.pdf"
                                >
                                  &nbsp;GRK National ESDM 2019
                                </a>
                              </p>
                              // eslint-disable-next-line
                              : selectedCategory == 6
                                ? <p>&nbsp;{translation.t('emission.form.label3')} :
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.ipcc-nggip.iges.or.jp/EFDB/find_ef.php?ipcc_code=2.B&ipcc_level=1"
                                  >
                                    &nbsp;IPCC EFDB Guidelines 2006
                                  </a>
                                </p>
                                // eslint-disable-next-line
                                : selectedCategory == 1
                                  ? <p>&nbsp;{translation.t('emission.form.label3')} :
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href="https://ditjenppi.menlhk.go.id/reddplus/images/adminppi/dokumen/igrk/Buku-EFDB.pdf"
                                    >
                                      &nbsp;EFDB National KLHK 2017
                                    </a>
                                  </p>
                                  // eslint-disable-next-line
                                  : selectedCategory != 0
                                    ? <p>&nbsp;{translation.t('emission.form.label3')} :
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub"
                                      >
                                        &nbsp;EPA 2023
                                      </a>
                                    </p>
                                    : ""
                        }
                      </div>
                    </div>
                  </div>
                  {
                    !optionEmissionFactor.length
                      ? <></>
                      : <div className='row'>
                        <div className="col-md-6" id="tour__type">
                          <div className="mb-3">
                            <label htmlFor="emission" className="form-label">
                              {
                                // eslint-disable-next-line
                                selectedCategory == 25
                                  ? translation.t('emission.form.mineDepth')
                                  // eslint-disable-next-line
                                  : selectedCategory == 22
                                    ? translation.t('emission.form.label21')
                                    // eslint-disable-next-line
                                    : selectedCategory == 21
                                      ? translation.t('emission.form.label21')
                                      // eslint-disable-next-line
                                      : selectedCategory == 20
                                        ? translation.t('emission.form.label21')
                                        // eslint-disable-next-line
                                        : selectedCategory == 18
                                          ? translation.t('emission.form.label22')
                                          // eslint-disable-next-line
                                          : selectedCategory == 7
                                            ? translation.t('emission.form.label8')
                                            // eslint-disable-next-line
                                            : (selectedCategory == 4 || selectedCategory == 26)
                                              ? translation.t('emission.form.label19')
                                              // eslint-disable-next-line
                                              : selectedCategory == 3
                                                ? translation.t('emission.form.label9')
                                                : translation.t('emission.form.label10')
                              }
                            </label>
                            {
                              listEmissionRecord.map((record, index) => {
                                return <select
                                  id='emission'
                                  key={index}
                                  onChange={e => onSelectEmission(e, index)}
                                  value={record.factor}
                                  className="form-select mb-0"
                                >
                                  {
                                    record.factor > 0
                                      ? ""
                                      : <option value="0">
                                        {
                                          translation.t('emission.form.select')
                                        } {
                                          // eslint-disable-next-line
                                          selectedCategory == 25
                                            ? translation.t('emission.form.mineDepth')
                                            // eslint-disable-next-line
                                            : selectedCategory == 22
                                              ? translation.t('emission.form.label21')
                                              // eslint-disable-next-line
                                              : selectedCategory == 21
                                                ? translation.t('emission.form.label21')
                                                // eslint-disable-next-line
                                                : selectedCategory == 20
                                                  ? translation.t('emission.form.label21')
                                                  // eslint-disable-next-line
                                                  : selectedCategory == 18
                                                    ? translation.t('emission.form.label22')
                                                    // eslint-disable-next-line
                                                    : selectedCategory === 7
                                                      ? translation.t('emission.form.label8')
                                                      // eslint-disable-next-line
                                                      : (selectedCategory == 4 || selectedCategory == 26)
                                                        ? translation.t('emission.form.label19')
                                                        // eslint-disable-next-line
                                                        : selectedCategory == 3
                                                          ? translation.t('emission.form.label9')
                                                          : translation.t('emission.form.label10')
                                        }
                                      </option>
                                  }
                                  {optionEmissionFactor}
                                </select>
                              })
                            }
                          </div>
                        </div>
                        <div className="col-md-6" id="tour__unit">
                          <div className="mb-3">
                            <label htmlFor="unit" className="form-label">
                              {translation.t('emission.form.label11')}
                            </label>
                            {listEmissionRecord.map((record, index) => {
                              const optionEmissionFactorUnit = listEmissionFactorUnit
                                .filter(unit => unit.unit_factor === record.factor)
                                .map((item) => {
                                  return <option key={item.unit_id} value={item.unit_id}>
                                    {item.unit_label}
                                  </option>
                                })

                              return <select
                                id='unit'
                                key={index}
                                onChange={e => onSelectUnit(e, index)}
                                value={record.unit}
                                className="form-select mb-0"
                              >
                                {
                                  record.unit > 0
                                    ? ""
                                    : <option value="0">
                                      {translation.t('emission.form.placeholder4')}
                                    </option>
                                }
                                {optionEmissionFactorUnit}
                              </select>
                            })}
                          </div>
                        </div>
                      </div>
                  }

                  {selectedCategory > 0 ? <div className="row">
                    <h5 className="pt-2">
                      {translation.t('emission.form.info2')}
                    </h5>
                    <p className="mb-3">
                      {translation.t('emission.form.subInfo2')}
                    </p>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            id="tour__template"
                            className='btn btn-success'
                            onClick={onDownloadTemplate}
                          >
                            {translation.t('general.btnDownloadTemplate')}
                          </button>
                          <div className="mb-3 mt-3">
                            <label htmlFor="formFile" className="form-label">
                              {translation.t('emission.form.label15')}
                            </label>
                            <a
                              className='d-block mb-3'
                              id="tour__instruction"
                              target="_blank"
                              rel="noreferrer"
                              href="https://truclimate.notion.site/truclimate/6509a1381b794aaca4ebe012891259af"
                            >
                              {translation.t('emission.form.instruction')}
                            </a>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                gap: '0.5rem',
                              }}
                            >
                              <button
                                onClick={onAttachFile}
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                id="tour__attachment"
                              >
                                {translation.t('emission.form.label17')}
                              </button>
                              <p className="text-muted mb-0">{selectedFile?.name}</p>
                            </div>
                            <input
                              onChange={onChangeFile}
                              style={{ display: "none" }}
                              className="form-control"
                              type="file"
                              id="formFile"
                              accept=".zip"
                            />
                          </div>
                          <p>{translation.t('emission.form.label18')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-end">
                        <button
                          onClick={onSaveRecord}
                          type="submit"
                          className="btn btn-primary"
                          id="tour__save"
                        >
                          {translation.t('general.btnSave')}
                        </button>
                      </div>
                    </div>
                  </div> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default connect(mapStateToProps, mapDispatchToProps)(EmissionCreateInBulk)
