import { useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import {
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
} from '../../library/helper'

export default function EmptyPolarAreaChart() {
  const { i18n } = useTranslation()

  const [series] = useState([95, 70, 95, 70])

  const emptyOptions = {
    chart: {
      id: 'chartPolarAreaEmpty',
      type: 'polarArea',
      height: 400,
      offsetX: 0,
      offsetY: 0,
      width: 200
    },
    colors: [EMPTY_CHART_COLORS[0]],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    },
    stroke: {
      colors: ['#fff']
    },
    fill: {
      opacity: 0.8
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return twoDecimalFormat(val, i18n.language) + " tCO₂e"
        },
      },
      enabled: false,
    }
  }

  return (
    <ApexCharts
      options={emptyOptions}
      series={series}
      type="polarArea"
      height={200}
    />
  );
}
